.button-1 {
  width: fit-content;
  height: fit-content;
  padding: 0px 10px 0px 10px;
  margin: 10px 10px 10px 10px;

  background-color: var(--color2);

  border-style: solid;
  border-width: 3px;
  border-radius: 10px;
  filter: drop-shadow(0px 8px 0px var(--color1));
  border-color: var(--color1);

  cursor: pointer;
  transition: 0.1s;

  p {
    width: 100%;
    height: 100%;
    margin: 0px;

    color: var(--text1);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.button-1.small {
  padding: 0px 10px 0px 10px;
  border-width: 3px;
  border-radius: 10px;
  p {
    font-weight: 500;
    font-size: 18px;
  }
}

.button-1.medium {
  padding: 0px 15px 0px 15px;
  border-width: 5px;
  border-radius: 17px;
  p {
    font-weight: 600;
    font-size: 24px;
  }
}

.button-1.large {
  padding: 0px 20px 0px 20px;
  border-width: 6px;
  border-radius: 20px;
  p {
    font-weight: 700;
    font-size: 30px;
  }
}

.button-1:hover {
  filter: drop-shadow(0px 6px 0px var(--color1));
  transform: translate(0px, 2px);
  transition: 0.1s;
}

.button-1:active {
  filter: drop-shadow(0px 0px 0px var(--color1));
  transform: translate(0px, 8px);
  transition: 0.1s;
}

.textbox {
  width: 200px;
  height: 50px;
  padding: 0px 15px;
  margin: auto;

  border-radius: 15px;
  border-style: none;
  background: var(--color2);

  font-size: 28px;
  font-weight: 400;
  line-height: normal;
}

input[type="text"]:focus {
  border-color: var(--color1); /* Change the border color when focused */
  border-width: 3px;
  outline: none; /* Remove the default focus outline */
}
